<template>
  <div>
    <div class="regalo-shops" v-if="!retailerIsHelloBB">
      <h5 class="regalo-shops__title">{{ $t("productPage.itsSoldIn") }}</h5>
      <label
        class="regalo-shops__item"
        :for="'retailer_' + productRetailer.name"
        v-for="(productRetailer, index) in productRetailers"
        :key="index"
      >
        <div class="regalo-shops__shop">
          <img
            :src="$getLogoRetalier(productRetailer.name)"
            alt="Retailer logo"
          />
          <span v-text="productRetailer.name"></span>
        </div>
        <div class="regalo-shops__price">
          {{ $utils.formatMoney(productRetailer.price, currency) }}
        </div>
        <div class="regalo-shops__button">
          <a
            class="button button--primary button--sm button--ico"
            @click="openBuy(productRetailer)"
          >
            <i class="uil uil-external-link-alt">
              <!-- Afegir uil--active una volta copiat -->
            </i>
            {{ $t("generic.buy") }}</a
          >
        </div>
      </label>
    </div>

    <div class="regalo-actions">
      <shopify-add-to-cart-button
          v-show="product.shopifyId"
          :product="product"
          :comprarYa="true"
          @opening="sendMixPanelInfo()"
      ></shopify-add-to-cart-button>
      <div
        v-show="product.status !== 3"
        @click="openBooking"
        class="button button--transparent button--block button--ico"
      >
        <i class="uil uil-padlock"></i> {{ $t("guest.reserve") }}
      </div>
      <button
        v-show="showCancelBooking()"
        @click="openCancelBooking"
        class="button button--secondary button--block"
      >
        Cancelar reserva
      </button>
    </div>

    <!--    ALREADY BOUGHT MODAL-->
    <transition name="bbFade" @after-enter="showAlreadyBoughtContent = true">
      <div
        class="modal"
        v-if="showAlreadyBought"
        @click="showAlreadyBoughtContent = false"
      >
        <transition name="bbUp" @after-leave="closeAlreadyBought()">
          <div
            v-show="showAlreadyBoughtContent"
            class="modal__card"
            @click.stop=""
          >
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back"></div>
                <div class="modal__close">
                  <img
                    src="../../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showAlreadyBoughtContent = false"
                  />
                </div>
              </div>
              <div class="modal__content">
                <div class="flux-regalo">
                  <div class="flux-regalo__title">¿Lo has comprado ya?</div>

                  <div class="flux-regalo__msg">
                    Marca que sí sólo si ya lo has hecho.
                  </div>
                  <div class="flux-regalo__msg flux-regalo__msg--small">
                    <img
                      src="../../../assets/img/ico/ico-question.svg"
                      alt=""
                    />
                    Este paso no se puede deshacer y puede provocar líos en la
                    lista de {{ myList.ownerName }} si se hace mal.
                  </div>

                  <div class="flux-regalo__actions">
                    <loading-button v-if="loadingMarcarComoRegalado" />
                    <a
                      v-else
                      href="#"
                      class="button button--primary"
                      @click="marcarComoRegalado()"
                      >Sí, marcar como regalado,</a
                    >
                  </div>
                  <list-owner-address :list="list"></list-owner-address>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <!--
      BOOKING MODAL:
        Step 1 : Reservar formulari nombre y email  ->  FluxRegaloReservaStep1
        Step2 Confirmació i recordatori -> FluxRegaloReservaStep2
        Back to list
    -->
    <transition name="bbFade" @after-enter="showBookingContent = true">
      <div
        class="modal"
        v-show="showBooking"
        @click="showBookingContent = false"
      >
        <transition name="bbUp" @after-leave="closeBooking()">
          <div v-show="showBookingContent" class="modal__card" @click.stop="">
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back" v-show="bookingStep > 2">
                  <img
                    @click="bookingStep--"
                    src="../../../assets/img/ico/ico-arrow-back.svg"
                    alt="Atras"
                  />
                </div>
                <div class="modal__title">
                  <slot name="titulo"></slot>
                </div>
                <div class="modal__close">
                  <img
                    src="../../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showBookingContent = false"
                  />
                </div>
              </div>
              <div class="modal__content">
                <FluxRegaloReservaStep1
                  v-show="bookingStep === 1"
                  :product="product"
                  :list="list"
                  @next="nextFirstStep"
                ></FluxRegaloReservaStep1>
                <FluxRegaloReservaStep2
                  v-show="bookingStep === 2"
                  @next="finishBooking()"
                ></FluxRegaloReservaStep2>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <!--  PRESENT MODAL-->
    <transition name="bbFade" @after-enter="showBuyContent = true">
      <div class="modal" v-if="showBuy" @click="showBuyContent = false">
        <transition name="bbUp" @after-leave="closeBuy()">
          <div v-show="showBuyContent" class="modal__card" @click.stop="">
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back" v-show="buyStep > 1">
                  <img
                    @click="buyStep--"
                    src="../../../assets/img/ico/ico-arrow-back.svg"
                    alt="Atras"
                  />
                </div>
                <div class="modal__title">
                  <slot name="titulo"></slot>
                </div>
                <div class="modal__close">
                  <img
                    src="../../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showBuyContent = false"
                  />
                </div>
              </div>
              <div class="modal__content" ref="scroll_container">
                <FluxRegaloStep1
                  v-if="buyStep === 1"
                  :product="product"
                  :list="list"
                  :retailer="retailer"
                  @next="nextFirstStepBuy"
                ></FluxRegaloStep1>
                <FluxRegaloStep2
                  v-if="buyStep === 2"
                  :product="product"
                  :list="list"
                  :retailer="retailer"
                ></FluxRegaloStep2>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <!-- CANCEL MODAL-->
    <transition name="bbFade" @after-enter="showCancelContent = true">
      <div class="modal" v-show="showCancel" @click="showCancelContent = false">
        <transition name="bbUp" @after-leave="closeCancel()">
          <div v-show="showCancelContent" class="modal__card" @click.stop="">
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back"></div>
                <div class="modal__title"></div>
                <div class="modal__close">
                  <img
                    src="../../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showCancelContent = false"
                  />
                </div>
              </div>
              <div class="modal__content">
                <div class="card-success">
                  <div class="card-success__header">
                    ¿Quieres cancelar esta reserva?
                  </div>
                  <div class="card-success__content">
                    Si cancelas la reserva, el producto volverá a estar
                    disponible para otros usuarios.
                  </div>
                  <div
                    class="card-success__actions card-success__actions--vertical card-success__actions--50"
                  >
                    <loading-button
                      class=""
                      v-if="loadingCancelBooking"
                    ></loading-button>
                    <button
                      v-else
                      class="button button--primary"
                      @click="cancelBooking"
                    >
                      Cancelar la reserva
                    </button>
                    <button
                      class="button button--secondary"
                      @click="showCancelContent = false"
                    >
                      Mantener la reserva
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import FluxRegaloStep1 from "./FluxRegaloStep1";
import FluxRegaloStep2 from "./FluxRegaloStep2";
import FluxRegaloReservaStep1 from "./FluxRegaloReservaStep1";
import FluxRegaloReservaStep2 from "./FluxRegaloReservaStep2";
import ListOwnerAddress from "./ListOwnerAddress";
import CompradoYa from "./mixins/CompradoYa";
import LoadingButton from "../../../components/LoadingButton";
import { mapGetters } from "vuex";
import ShopifyAddToCartButton from "../../shared/ShopifyAddToCartButton";

export default {
  name: "ListProductSelectShop",
  mixins: [CompradoYa],
  components: {
    FluxRegaloStep1,
    FluxRegaloStep2,
    FluxRegaloReservaStep1,
    FluxRegaloReservaStep2,
    "list-owner-address": ListOwnerAddress,
    "loading-button": LoadingButton,
    ShopifyAddToCartButton,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    list: {
      required: true,
    },
  },
  data() {
    return {
      loadingCancelBooking: false,
      retailer: null,
      showBuy: false,
      showBuyContent: false,
      showBooking: false,
      showBookingContent: false,
      bookingStep: 1,
      buyStep: 1,
      showCancel: false,
      showCancelContent: false,
      showAlreadyBought: false,
      showAlreadyBoughtContent: false,
    };
  },
  computed: {
    ...mapGetters({
      myList: "myList",
      currency: "myListCurrency",
    }),
    productRetailers() {
      if (this.product.isFree) {
        return [
          {
            name: this.$utils.capitalize(this.product.retailerName),
            price: this.product.price,
          },
        ];
      }
      return this.product.productRetailers;
    },
    productRetailersName() {
      return this.product.productRetailers.map(function (item) {
        return item["name"];
      });
    },
    SoldByHelloBB() {
      if (this.productRetailersName.includes("HelloBB")) {
        return "Active";
      }
      return "No";
    },
    retailerIsHelloBB() {
      return this.retailer?.name === "HelloBB" || this.product.shopifyId;
    },
  },
  methods: {
    sendMixPanelInfo() {
      if (this.myList) {
        window.mixpanel.identify(this.myList.userId);
        window.mixpanel.track("Comprar", {
          "Object name": this.product.name,
          "Selected Vendor": "HelloBB",
          "Retailer name": "HelloBB",
          "Object price": this.product.price,
          "List URL": "https://www.hellobb.net/guest/" + this.myList.id,
          "Buyer role": "Convidat",
          Src: "fitxa del regalador",
          "Shopify BuyButton": "Si",
          "Sold by HelloBB": this.SoldByHelloBB,
          "Item Retailers": this.productRetailersName,
        });
      }
    },
    sortRetailersByPrice(retailers) {
      return retailers.sort((a, b) => {
        return this.priceComparison(a, b);
      });
    },
    priceComparison(a, b) {
      if (a.price < b.price) {
        return -1;
      }
      if (a.price > b.price) {
        return 1;
      }
      // a must be equal to b
      return 0;
    },
    openCancelBooking() {
      this.showCancel = true;
      document.body.classList.add("modal-open");
    },
    openBooking() {
      document.body.classList.add("modal-open");
      this.showBooking = true;
    },
    openAlreadyBought() {
      document.body.classList.add("modal-open");
      this.showAlreadyBought = true;
    },
    openBuy(pProductRetailer) {
      document.body.classList.add("modal-open");
      this.showBuy = true;
      this.retailer = pProductRetailer;
      window.mixpanel.identify(this.myList.userId);
      window.mixpanel.track("Comprar", {
        "Object name": this.product.name,
        "Retailer name": this.retailer?.name,
        "Selected Vendor": this.retailer?.name,
        "Object price": this.product.price,
        "List URL": "https://www.hellobb.net/guest/" + this.myList.id,
        "Buyer role": "Convidat",
        Src: "fitxa del regalador",
        "Sold by HelloBB": this.SoldByHelloBB,
        "Item Retailers": this.productRetailersName,
      });
    },
    async cancelBooking() {
      try {
        this.loadingCancelBooking = true;
        await this.$store.dispatch("cancelBooking",{ product: this.product, email: this.$route.query.email, id: this.$route.query.id });
        this.loadingCancelBooking = false;
        this.showCancelContent = false;
        document.body.classList.remove("modal-open");
        this.$emit("update");
        this.$sleep(
          () =>
            this.$router.push({ name: "guest", params: { id: this.list.id } }),
          1000,
          true
        );

        window.mixpanel.identify(this.myList.userId);
        window.mixpanel.track("Cancelar reserva", {
          "Object name": this.product.name,
          "Cancelled by": "Buyer",
        });
      } catch (error) {
        console.log(error);
      }
    },
    showCancelBooking() {
      return (
        this.product.status === 3 &&
        this.$route.query.email &&
        this.$route.query.id
      );
    },
    resetScroll() {
      this.$refs.scroll_container.scrollTop = 0;
    },
    nextFirstStepBuy() {
      window.mixpanel.identify(this.myList.userId);
      window.mixpanel.track("Redireccionar a retailer", {
        "Object name": this.product.name,
        "Selected Vendor": this.retailer?.name,
        "Object price": this.product.price,
        "List URL": "https://www.hellobb.net/guest/" + this.myList.id,
        "Buyer role": "Convidat",
        Src: "fitxa del regalador",
        "Sold by HelloBB": this.SoldByHelloBB,
        "Item Retailers": this.productRetailersName,
      });
      this.$emit("update");
      this.buyStep++;
      this.resetScroll();
    },
    nextFirstStep() {
      this.$emit("update");
      this.bookingStep++;
    },
    finishBooking() {
      this.showBookingContent = false;
      document.body.classList.remove("modal-open");
      this.bookingStep = 1;
      this.$router.push({ name: "guest", params: { id: this.list.id } });
    },
    closeBooking() {
      this.showBooking = false;
      document.body.classList.remove("modal-open");
      this.$emit("close");
      if (this.bookingStep === 2)
        this.$router.push({ name: "guest", params: { id: this.list.id } });
    },
    closeAlreadyBought() {
      this.showAlreadyBought = false;
      document.body.classList.remove("modal-open");
      this.$emit("close");
    },
    closeCancel() {
      this.showCancel = false;
      document.body.classList.remove("modal-open");
      this.$emit("close");
    },
    closeBuy() {
      this.showBuy = false;
      document.body.classList.remove("modal-open");
      this.buyStep = 1;
      this.$emit("close");
    },
  },
  beforeMount() {
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        this.$emit("close");
        this.showBookingContent = false;
        this.showBuyContent = false;
        this.showCancelContent = false;
        this.showAlreadyBoughtContent = false;
      }
    };
    document.addEventListener("keydown", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
  },
  mounted() {
    this.retailer = this.productRetailers[0];
  },
};
</script>
